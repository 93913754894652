import clsx from 'clsx'
import type { HTMLAttributes } from 'react'
import styles from './pulse.module.css'

/**
 * Use this component to compose skeleton loading indicators.
 */
export function Pulse(props: HTMLAttributes<HTMLDivElement>) {
  return <div {...props} className={clsx(styles.pulse, props.className)} />
}
